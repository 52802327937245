"use client";
import React from "react";
import styled from "styled-components";
import { device } from "../../lib/breakpoints";
import { Row, Col } from "antd";
import { APP_NAME } from "../../helpers/constants";
import { NavLink } from "react-router-dom";

const services = [
  {
    name: "Hair Transplant",
    icon: "/icons/services/hair-transplant.svg",
    description:
      "Visualize natural-looking results before a patient's hair restoration procedure",
  },
  {
    name: "Mammoplasty",
    icon: "/icons/services/mammoplasty.svg",
    description: "See how breast augmentation can enhance patient proportions",
  },
  {
    name: "Botox",
    icon: "/icons/services/botox.svg",
    description:
      "Preview how targeted muscle relaxation can smooth wrinkles and enhance facial harmony",
  },
  {
    name: "Liposuction",
    icon: "/icons/services/liposuction.svg",
    description:
      "Visualize potential body contouring results and improved proportions through fat removal",
  },
  {
    name: "Rhinoplasty",
    icon: "/icons/services/rhinoplasty.svg",
    description:
      "Preview nose reshaping outcomes tailored to your patient's facial features",
  },
  {
    name: "Facelift",
    icon: "/icons/services/facelift.svg",
    description:
      "Explore rejuvenation possibilities with realistic facial visualizations",
  },
  {
    name: "Lip Enhancement",
    icon: "/icons/services/lip-enhancement.svg",
    description: "View potential results of lip augmentation procedures",
  },
  {
    name: "Blepharoplasty",
    icon: "/icons/services/blepharoplasty.svg",
    description:
      "Show patients how eyelid surgery can rejuvenate their appearance",
  },
  {
    name: "Beard Transplant",
    icon: "/icons/services/beard-transplant.svg",
    description:
      "Show patients how a fuller, natural-looking beard can transform their appearance",
  },
  {
    name: "Eyebrow Transplant",
    icon: "/icons/services/eyebrow-transplant.svg",
    description:
      "Demonstrate how restored or reshaped eyebrows can enhance facial symmetry",
  },
  {
    name: "Hair Removal",
    icon: "/icons/services/hair-removal.svg",
    description:
      "Preview the results of permanent hair removal treatments on any body area",
  },
  {
    name: "Tattoo Removal",
    icon: "/icons/services/tattoo-removal.svg",
    description:
      "Preview the results of tattoo removal treatments on any body area",
  },
];

export default function HomeServices() {
  const [showAllServices, setShowAllServices] = React.useState(false);
  const displayedServices = showAllServices ? services : services.slice(0, 6);

  return (
    <Container>
      <ContentWrapper>
        <SectionTitle>Prediction Services</SectionTitle>
        <SectionSubtitle>
          Show patients how they could look after their procedure with our
          AI-powered predictions. Increase consultation confidence and reduce
          patient hesitation.{" "}
          <CTALink to="/contact">Schedule your demo today →</CTALink>
        </SectionSubtitle>

        <ServicesGrid gutter={[40, 56]}>
          {displayedServices.map((service, index) => (
            <Col xs={24} sm={12} lg={8} key={index}>
              <ServiceCard>
                <IconPlaceholder>
                  <ServiceIcon src={service.icon} alt={service.name} />
                </IconPlaceholder>
                <ServiceName>{service.name}</ServiceName>
                <ServiceDescription>{service.description}</ServiceDescription>
              </ServiceCard>
            </Col>
          ))}
        </ServicesGrid>

        {!showAllServices && services.length > 6 && (
          <ShowMoreButton onClick={() => setShowAllServices(true)}>
            View All {services.length} Services
            <ChevronIcon>▼</ChevronIcon>
          </ShowMoreButton>
        )}
      </ContentWrapper>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  padding: 8rem 0;
  background-color: white;

  @media ${device.tablet} {
    padding: 7rem 0;
  }

  @media ${device.mobile} {
    padding: 6rem 0;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 3rem;
  text-align: center;

  @media ${device.tablet} {
    padding: 0 2.5rem;
  }

  @media ${device.mobile} {
    padding: 0 2rem;
  }
`;

const SectionTitle = styled.h2`
  font-size: 3rem;
  font-weight: 600;
  color: var(--color-brand-complimentary);
  margin: 0;

  @media ${device.tablet} {
    font-size: 2.5rem;
  }

  @media ${device.mobile} {
    font-size: 2rem;
  }
`;

const SectionSubtitle = styled.p`
  font-size: 1.3rem;
  line-height: 1.6;
  color: #666;
  margin: 1.2rem 0 1.5rem;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;

  @media ${device.tablet} {
    font-size: 1.2rem;
    margin: 1rem 0 1.2rem;
  }

  @media ${device.mobile} {
    font-size: 1.15rem;
    line-height: 1.7;
    margin: 1rem 0 1rem;
  }
`;

const ServicesGrid = styled(Row)`
  margin-top: 2rem;
  justify-content: center;
`;

const ServiceCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 2rem;
  border-radius: 10px;
  background: white;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.07);
  height: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.12);
  }

  @media ${device.tablet} {
    padding: 2.2rem 1.8rem;
  }

  @media ${device.mobile} {
    padding: 2rem 1.5rem;
  }
`;

const IconPlaceholder = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: rgba(0, 123, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.8rem;

  @media ${device.tablet} {
    width: 85px;
    height: 85px;
    margin-bottom: 1.6rem;
  }

  @media ${device.mobile} {
    width: 80px;
    height: 80px;
    margin-bottom: 1.5rem;
  }
`;

const ServiceIcon = styled.img`
  width: 45px;
  height: 45px;
  opacity: 0.85;

  @media ${device.mobile} {
    width: 40px;
    height: 40px;
  }
`;

const PlusIcon = styled.div`
  font-size: 2.5rem;
  color: var(--color-brand-primary);
  font-weight: 300;
`;

const ServiceName = styled.h3`
  font-size: 1.4rem;
  font-weight: 600;
  margin: 0 0 0.9rem;
  color: var(--color-brand-primary);

  @media ${device.tablet} {
    font-size: 1.35rem;
    margin: 0 0 0.8rem;
  }

  @media ${device.mobile} {
    font-size: 1.25rem;
    margin: 0 0 0.75rem;
  }
`;

const ServiceDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #666;
  margin: 0;

  @media ${device.tablet} {
    font-size: 0.95rem;
  }

  @media ${device.mobile} {
    font-size: 0.95rem;
    line-height: 1.5;
  }
`;

const CTALink = styled(NavLink)`
  color: var(--color-brand-primary);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
  white-space: nowrap;

  &:hover {
    color: var(--color-brand-complimentary);
    text-decoration: underline;
  }
`;

const ShowMoreButton = styled.button`
  margin-top: 3rem;
  padding: 1rem 2.5rem;
  background: var(--color-brand-primary);
  color: white;
  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
  box-shadow: 0 4px 16px rgba(0, 123, 255, 0.15);
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    background: var(--color-brand-complimentary);
    transform: translateY(-4px);
    box-shadow: 0 8px 24px rgba(0, 123, 255, 0.25);
  }

  @media ${device.tablet} {
    padding: 0.9rem 2.2rem;
    font-size: 1.05rem;
  }

  @media ${device.mobile} {
    padding: 0.8rem 2rem;
    font-size: 1rem;
    margin-top: 2.5rem;
  }
`;

const ChevronIcon = styled.span`
  font-size: 0.8em;
  transition: transform 0.3s ease;
  ${ShowMoreButton}:hover & {
    transform: translateY(2px);
  }
`;
